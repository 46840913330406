import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { RouteWithLayout } from "../components";
import DashboardLayout from "../components/Layout/DashboardLayout";
import AddPatient from "../views/Dashboard/AddPatient";
import EditTherapist from "../views/Dashboard/Organizations/EditTherapist";
import ManageOrganizations from "../views/Dashboard/Organizations/ManageOrganizations";
import Organizations from "../views/Dashboard/Organizations/Organizations";
import OrganizationsForm from "../views/Dashboard/Organizations/OrganizationsForm";
import PDFViewer from "../views/Dashboard/PDFViewer";
import TherapistList from "../views/Dashboard/TherapistList";
import UserProfile from "../views/Dashboard/UserProfile";
const Home = React.lazy(() => import('../views/Home/Home'));
const Login = React.lazy(() => import('../views/Login/Login'));
const Signup = React.lazy(() => import('../views/Login/Signup'));
const PasswordReset = React.lazy(() => import('../views/Login/PasswordReset'));
const Dashboard = React.lazy(() => import('../views/Dashboard/Dashboard'));

const Routes = (props) => {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/reset-pwd" component={PasswordReset} />
            <RouteWithLayout
                exact
                path="/dashboard"
                layout={DashboardLayout}
                component={Dashboard}
            />
            <RouteWithLayout
                exact
                path="/addpatient"
                layout={DashboardLayout}
                component={AddPatient}
            />
            <RouteWithLayout
                exact
                path="/editpatient/:id"
                layout={DashboardLayout}
                component={AddPatient}
            />
            <RouteWithLayout
                exact
                path="/therapist"
                layout={DashboardLayout}
                component={TherapistList}
            />
            <RouteWithLayout
                exact
                path="/user-profile"
                layout={DashboardLayout}
                component={UserProfile}
            />
            <RouteWithLayout
                exact
                path="/manage"
                layout={DashboardLayout}
                component={ManageOrganizations}
            />
            <RouteWithLayout
                exact
                path="/manage-organization"
                layout={DashboardLayout}
                component={Organizations}
            />
            <RouteWithLayout
                exact
                path="/add-organization"
                layout={DashboardLayout}
                component={OrganizationsForm}
            />
            <RouteWithLayout
                exact
                path="/edit-organization/:id"
                layout={DashboardLayout}
                component={OrganizationsForm}
            />
            <RouteWithLayout
                exact
                path="/manage/edit-therapist/:id"
                layout={DashboardLayout}
                component={EditTherapist}
            />
            <RouteWithLayout
                exact
                path="/consent-forms/:file"
                layout={DashboardLayout}
                component={PDFViewer}
            />
            {/* <Route exact path="/not-found" component={PagenotFound} /> */}
            <Redirect from="*" to="/not-found" />
        </Switch>
    )
}

export default Routes;