import React, { useEffect, useState } from "react";
import { withFirebase } from "../../../services";
import search from "../../../assets/image/search.png";
import logo from "../../../assets/image/logo.png";
import { Switch } from 'evergreen-ui';
import { CustomPopover, CustomSweetAlert } from "../../../components/generic";
import { history } from "../../../common";

function ManageOrganizations(props) {
    const { firebase } = props;
    const [authUser, setAuthUser] = useState(null);
    const [therapistList, settherapistList] = useState(null)
    const [SweetAlert, setSweetAlert] = useState({
        show: false,
        type: "default",
        onConfirm: () => { setSweetAlert({ ...setSweetAlert, show: false }); },
        onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
    });
    useEffect(() => {
        if (firebase.authUser) {
            setAuthUser(firebase.authUser);
            if (props.location.search === "?reload=true") {
                history.push("/manage");
                window.location.reload();
            }
        }
        return () => { };
    }, [firebase.authUser]);

    useEffect(() => {
        if (authUser) {
            getAllORGMembers();
        }
        return () => {

        };
    }, [authUser])

    const getAllORGMembers = async () => {

        let ref = firebase.db.collection("users")
            .where("accountType", "==", 2);
        ref.onSnapshot((data) => {
            let therapist = []
            data.forEach(function (doc) {
                therapist = therapist.concat({ id: doc.id, ...doc.data() })
            });
            settherapistList(therapist);
        });
    }

    const onAction = (id, action) => {
        switch (action) {
            case "VIEW":
                break;
            case "EDIT":
                history.push("/manage/edit-therapist/" + id);
                break;
            case "DELETE":
                setSweetAlert({
                    showCancel: true,
                    show: true,
                    type: "warning",
                    title: "Are you sure?",
                    message: "You want to delete this Therapist.",
                    onConfirm: () => { setSweetAlert({ ...setSweetAlert, show: false }); onMembersRemove(id); },
                    onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
                })
                break;
            default:
                break;
        }
    }

    const removeUserFromOrg = async (data) => {

        if (data) {
            let ref = firebase.db.collection("organizations/" + data.organizationID + "/administrators").doc(data.userID);
            await ref.delete();
        } else {
            return
        }
    }

    const onMembersRemove = async (id) => {

        try {
            let ref = firebase.db.collection("users");
            await ref.doc(id).onSnapshot((doc) => {
                removeUserFromOrg(doc.data())
            })

        } catch (error) {
            console.log("Error while remove user from organization", error)
        }

        try {
            const deleteAuthUser = firebase.functions.httpsCallable('deleteAuthUser');
            await deleteAuthUser({ uid: id }).then(async (data) => {
                await firebase.db.collection(`users`).doc(id).delete();
            }).catch((error) => console.log(error))
        } catch (error) {
            console.log("onMembersRemove Error =>", error)
        }
    }

    return (
        <>
            <div className="dashboard-area">
                <div className="list-section">
                    <div className="title">
                        <h2>Manage Therapist
                        </h2>
                        <div className="title-btn">
                            <div className="search-input">
                                <input type="text" placeholder="Search" />
                                <div className="img">
                                    <img src={search} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <List data={therapistList}
                        onAction={onAction}
                        firebase={firebase}
                        authUser={authUser}
                    />

                </div>
            </div>
            <CustomSweetAlert {...SweetAlert} />
        </>
    )
}

export default withFirebase(ManageOrganizations);

function List(props) {
    const [isCheck, setisCheck] = useState(false);
    const { data, onAction, firebase, authUser } = props

    const onCheck = async (e) => {
        try {
            let ref = firebase.db.collection("users").doc(e.target.name);
            setisCheck({ ...isCheck, [e.target.name]: e.target.checked })
            await ref.get().then((data) => {
                let newData = data.data();
                newData.subscriptionEnabled = !e.target.checked;
                ref.set(newData, { merge: true }).then(() => console.log("Done")).catch((error) => console.log(error));
            })
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div className="table">
            {authUser ?
                <table>
                    <thead>
                        <tr>
                            <th>Photo</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Subscription</th>
                            <th></th>
                            {authUser.isAdmin ? <th></th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {data ? data.length > 0 ? data.map((item) => {
                            return (<ListRow
                                item={item}
                                onCheck={onCheck}
                                firebase={firebase}
                                onAction={onAction}
                                authUser={authUser} />)
                        }) :
                            <tr>
                                <td colSpan="4">No Record Found.</td>
                            </tr>
                            :
                            <tr>
                                <td colSpan="4">Loading..</td>
                            </tr>}
                    </tbody>
                </table>
                : null}
        </div>
    )
}

const ListRow = (props) => {
    const { item, onCheck, firebase, onAction, authUser } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [member, setMember] = useState(null);

    useEffect(() => {
        getMembersInfo(item.id)
        return () => {

        };
    }, [item])
    const getMembersInfo = async (id) => {
        try {
            let refUsers = firebase.db.collection("users");
            await refUsers.doc(id).onSnapshot((doc) => {
                setMember({ id: doc.id, ...doc.data() })
            })
        } catch (error) {

        }
    }

    const DisableAuthUser = async () => {
        setIsLoading(true);
        let ref = firebase.db.collection("users").doc(item.id);
        const disableAuthUser = firebase.functions.httpsCallable('disableAuthUser');
        await disableAuthUser({ uid: item.id, isDisable: !item.isDisabled }).then(async (data) => {
            await ref.get().then((data) => {
                let newData = data.data();
                newData.isDisabled = !item.isDisabled;
                ref.set(newData, { merge: true }).then(() => { setIsLoading(false); }).catch((error) => console.log(error));
            })
        }).catch((error) => { console.log(error); setIsLoading(false); })
    }

    return (
        member ?
            <tr>
                <td className="img"><img src={member.photoURL ? member.photoURL : logo} alt="" /></td>
                <td>{member.name} {authUser.uid === member.id ? "(You)" : null}</td>
                <td>{member.email}</td>
                <td className="option">
                    <div className="switch">
                        <Switch name={member.id} height={20} checked={member.subscriptionEnabled} onChange={onCheck} />
                    </div>
                </td>
                <td>
                    <button onClick={DisableAuthUser} className={member.isDisabled ? "enable-authuser-btn" : "disable-authuser-btn"}>
                        {isLoading ?
                            <><i className="fa fa-spinner" aria-hidden="true"></i> Loading..</>
                            : member.isDisabled ?
                                <><i className="fa fa-check" aria-hidden="true"></i> Enable </>
                                : <> <i className="fa fa-ban" aria-hidden="true"></i> Disable </>} </button>
                </td>
                {authUser.isAdmin ? <td className="option" style={{ width: "30px" }}>
                    <CustomPopover id={member.id} onAction={onAction} />
                </td> : null}
            </tr> : null
    );
}