import React, { Suspense, useEffect } from 'react';
import { history } from './common';
import { Router } from 'react-router-dom';
import Routes from './Routes';
import Firebase, { FirebaseContext } from './services';
import AOS from "aos"

function App() {
  useEffect(() => {
    AOS.init({
        delay: 250,
        duration: 600,
    });
    return () => {
    }
}, [])
  return (
    <FirebaseContext.Provider value={new Firebase()}>
      <Suspense fallback={<> </>}>
        <Router history={history}>
          <Routes />
        </Router>
      </Suspense>
    </FirebaseContext.Provider>
  );
}

export default App;