import React, { useEffect, useState } from 'react'
import search from "../../../assets/image/search.png";
import { CustomPopover, CustomSweetAlert } from '../../../components/generic';
import { withFirebase } from '../../../services';
import { history } from '../../../common';

function Organizations(props) {
    const { firebase } = props
    const [authUser, setAuthUser] = useState(null)
    const [OrgList, setOrgList] = useState(null)
    const [SweetAlert, setSweetAlert] = useState({
        show: false,
        type: "default",
        onConfirm: () => { setSweetAlert({ ...setSweetAlert, show: false }); },
        onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
    });

    useEffect(() => {
        if (firebase.authUser) {
            setAuthUser(firebase.authUser);
        }
        return () => { };
    }, [firebase.authUser]);

    useEffect(() => {
        if (authUser) {
            getORGName()
        }
        return () => {

        };
    }, [authUser])

    const getORGName = async () => {
        try {
            let ref = firebase.db.collection("organizations")
            ref.onSnapshot((data) => {
                let ORG = []
                data.forEach(function (doc) {
                    ORG = ORG.concat({ id: doc.id, ...doc.data() })
                });
                setOrgList(ORG);
            });
        } catch (error) {
            console.log("Error->", error)
        }
    }

    const onAction = (id, action) => {
        switch (action) {
            case "VIEW":
                break;
            case "EDIT":
                history.push("/edit-organization/" + id);
                break;
            case "DELETE":
                setSweetAlert({
                    showCancel : true,
                    show: true,
                    type: "warning",
                    title: "Are you sure?",
                    message: "You want to delete this organization.",
                    onConfirm: () => { setSweetAlert({ ...setSweetAlert, show: false }); onOrganizationRemove(id); },
                    onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
                })                
                break;
            default:
                break;
        }
    }

    const onOrganizationRemove = async (id) => {
        try {
            await firebase.db.collection("organizations").doc(id).delete();
        } catch (error) {
            console.log("onMembersRemove Error =>", error)
        }
    }

    return (
        <>
            <div className="dashboard-area">
                <div className="list-section">
                    <div className="title">
                        <h2>Manage Organizations
                        </h2>
                        <div className="title-btn">
                            <div className="search-input">
                                <input type="text" placeholder="Search" />
                                <div className="img">
                                    <img src={search} alt="" />
                                </div>
                            </div>
                            <div className="add-patient">
                                <button onClick={() => history.push("/add-organization")}> <i className="fa fa-plus" aria-hidden="true"></i> &nbsp;Add Organization</button>
                            </div>
                        </div>
                    </div>
                    <List
                        data={OrgList}
                        onAction={onAction} />
                </div>
            </div>
            <CustomSweetAlert {...SweetAlert} />
        </>
    )
}

export default withFirebase(Organizations)


function List(props) {
    const { data, onAction } = props
    return (
        <div className="table">
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Admin</th>
                        <th>Address</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data ? data.length > 0 ? data.map((item) =>
                        <tr>
                            <td>{item.name}</td>
                            <td></td>
                            <td>{item.address}</td>
                            <td className="option">
                                <CustomPopover id={item.id} onAction={onAction} />
                            </td>
                        </tr>) :
                        <tr>
                            <td colSpan="3">No Record Found.</td>
                        </tr>
                        :
                        <tr>
                            <td colSpan="3">Loading..</td>
                        </tr>}
                </tbody>
            </table>
        </div>
    )
}