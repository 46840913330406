import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyBwd_ifVYt2ozUw_jSIxn8Qpq-KTcrCYPA",
    authDomain: "emerhelp-174b0.firebaseapp.com",
    databaseURL: "https://emerhelp-174b0.firebaseio.com",
    projectId: "emerhelp-174b0",
    storageBucket: "emerhelp-174b0.appspot.com",
    messagingSenderId: "693224606029",
    appId: "1:693224606029:web:e24d5a5114189d2b598f04",
    measurementId: "G-KBX51CS08D"
};

class Firebase {
    authUser;
    constructor() {
        app.apps.length !== 1 ? app.initializeApp(firebaseConfig) : app.app();
        this.auth = app.auth();
        this.db = app.firestore();
        this.firestore = app.firestore;
        this.storage = app.storage();
        this.functions = app.functions();
    }

    dosignIn = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    dosignUp = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged((user) => {
            if (user) {
                this.isUserAdmin(user).then((data) => {
                    if (data !== false) {
                        this.authUser = { ...user, ...data };
                        return next({ ...user, ...data })
                    }
                    else { return fallback(); }
                })
            } else {
                this.authUser = null;
                return fallback();
            }
        });

    dosignOut = async () => await this.auth.signOut();

    isUserAdmin = async (user) => {
        let isAdmin = false;
        let ref;
        ref = this.db.doc(`users/${user.uid}`)
        await ref.get()
            .then(async doc => {
                if (doc.exists) {
                    if (doc.data().accountType === 2) {
                        isAdmin = { ...doc.data(), ref: ref };
                    } else {
                        isAdmin = false;
                    }
                } else {
                    isAdmin = false;
                }
            });
        return isAdmin;
    }

};

export class FirebaseApp {
    constructor() {
        var app2 = app.apps.length !== 2 ? app.initializeApp(firebaseConfig, "Secondary") : app.app();
        this.auth = app2.auth();
        this.db = app2.firestore();
        this.storage = app2.storage();
        this.functions = app2.functions();
    }
}

export default Firebase;