import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { history } from "../../common";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewer(props) {
    let { file } = useParams();
    const [numPages, setNumPages] = useState(null);
    const [pdfURL, setPdfURL] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1.5)
    const [isLoading, setLoading] = useState(false);
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    useEffect(() => {
        if (file) {
            let link = window.localStorage.getItem("pdflink");
            setPdfURL(link);
        }
        return () => {

        };
    }, [file])

    const zoomIn = () => {
        if (scale < 5) {
            setScale(scale + 0.5);
        }
    }

    const zoomOut = () => {
        if (scale > 1) {
            setScale(scale - 0.5);
        }
    }

    const prvPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    const nextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    }

    const getPdfURL = async () => {

    }

    const download = () => {
        let tempLink = document.createElement('a');
        tempLink.href = pdfURL;
        tempLink.setAttribute('download', file);
        tempLink.click();
    }
    return (
        <>
            {isLoading ? null :
                <>
                    <div className="pdf-footer">

                        <div className="pdf-page">
                            <button onClick={() => { history.goBack(); window.localStorage.removeItem("pdflink"); }}> <i className="fa fa-chevron-left" aria-hidden="true"></i> Go back </button>
                        </div>

                        <div className="pdf-page">
                            <button onClick={prvPage}> <i className="fa fa-chevron-left" aria-hidden="true"></i> </button>
                            <p>Page {pageNumber} of {numPages}</p>
                            <button onClick={nextPage}> <i className="fa fa-chevron-right" aria-hidden="true"></i> </button>
                        </div>

                        <div className="pdf-zoom">
                            <button onClick={download}> <i className="fa fa-download" aria-hidden="true"></i> </button>
                            <button onClick={zoomIn}> <i className="fa fa-search-plus" aria-hidden="true"></i> </button>
                            <button onClick={zoomOut}> <i className="fa fa-search-minus" aria-hidden="true"></i> </button>
                        </div>

                    </div>
                    <div className="pdf-view">
                        <Document
                            file={{ url: pdfURL }}
                            onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={console.error}
                        >
                            <Page pageNumber={pageNumber} scale={scale} />
                        </Document>
                    </div>
                </>
            }
        </>
    );
}

export default PDFViewer;