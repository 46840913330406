import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { FileUpload, FileUploadWithURL, history } from '../../../common';
import logo from "../../../assets/image/logo.png";
import { withFirebase } from '../../../services';
import { toast } from 'react-toastify';
import { Switch } from 'evergreen-ui';
import { Link } from 'react-router-dom';
import ConsentFormModal from './ConsentFormModal';
import { CustomSweetAlert } from '../../../components/generic';


function EditTherapist(props) {
    const { firebase, match } = props;
    const [AuthUser, setAuthUser] = useState(null);
    const [UserId, setUserId] = useState(null)
    const [isLoading, setisLoading] = useState(false);
    const [ORGs, setORGs] = useState([])
    const [newProfileImg, setnewProfileImg] = useState(false);
    const [ORGFocus, setORGFocus] = useState(false);
    const [userOldORG, setUserOldORG] = useState(null);
    const [ConsentForms, setConsentForms] = useState(null);
    const [ConsentFormModalShow, setConsentFormModalShow] = useState(false);
    const [oldOrgId, setOldOrgId] = useState(null)
    const ProfileForm = useFormik({
        initialValues: formValues,
        onSubmit: values => {
            onSubmit(values);
        }
    });
    const [SweetAlert, setSweetAlert] = useState({
        show: false,
        type: "default",
        onConfirm: () => { setSweetAlert({ ...setSweetAlert, show: false }); },
        onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
    });

    useEffect(() => {
        setisLoading(true);
        if (firebase.authUser) {
            setAuthUser(firebase.authUser);
        }
        return () => {

        }
    }, [firebase.authUser])

    useEffect(() => {
        if (AuthUser && match.params.id) {
            setUserId(match.params.id);
            getMembersInfo(match.params.id);
            getAllORG();
            getConsentForms();
        }
        return () => {

        };
    }, [AuthUser, match])

    const getAllORG = async () => {
        let refUsers = firebase.db.collection("organizations");
        await refUsers.onSnapshot((doc) => {
            let ORG = []
            doc.forEach((data) => {
                ORG = ORG.concat({ id: data.id, ...data.data() })
            })
            setORGs(ORG)
        });
    }

    const getMembersInfo = async (id) => {
        try {
            let refUsers = firebase.db.collection("users");
            await refUsers.doc(id).onSnapshot((doc) => {
                if (doc.data()) {
                    setValues({
                        name: doc.data().name ? doc.data().name : "",
                        email: doc.data().email ? doc.data().email : "",
                        photoURL: doc.data().photoURL ? doc.data().photoURL : "",
                        subscriptionEnabled: doc.data().subscriptionEnabled ? doc.data().subscriptionEnabled : false,
                        profileDisable: doc.data().isDisabled ? doc.data().isDisabled : false,
                        organizationID: doc.data().organizationID ? doc.data().organizationID : false
                    });
                    setOldOrgId(doc.data().organizationID ? doc.data().organizationID : null)
                    setUserOldORG(doc.id)
                    setisLoading(false);
                }
            }, error => {
                console.log(error)
            })
        } catch (error) {

        }
    }

    const getConsentForms = async () => {
        let refForms = firebase.db.collection("users/" + AuthUser.uid + "/consentforms");
        await refForms.onSnapshot((docs) => {
            let forms = [];
            docs.forEach(doc => {
                forms = forms.concat({ id: doc.id, ...doc.data() })
            });
            setConsentForms(forms);
        })
    }

    const onSubmit = async (data) => {
        setisLoading(true);
        let imgProps = {
            path: "profileImage",
            img: newProfileImg,
            firebase: firebase,
            name: data.name
        }

        if (newProfileImg) {
            await FileUploadWithURL(imgProps).then((url) => {
                updateUserProfile(data, url);
            }).catch((error) => { setisLoading(false); console.log("IMG URL", error) })
        } else {
            updateUserProfile(data, "");
        }
    }

    let { handleSubmit, values, handleChange, setFieldValue, touched, errors, setValues } = ProfileForm;

    const removeUserFromOldOrg = async () => {
        let ref = firebase.db.collection("organizations/" + oldOrgId + "/administrators").doc(UserId);
        await ref.delete();
    }

    const updateUserProfile = (data, photoURL) => {
        if (photoURL) { data.photoURL = photoURL; }
        let ref = firebase.db.collection('users').doc(UserId);

        ref.set(data, { merge: true })
            .then(() => {
                if (userOldORG !== data.organizationID) {
                   if(oldOrgId !== data.organizationID) {
                    removeUserFromOldOrg()
                    firebase.db.collection('organizations/' + data.organizationID + "/administrators").doc(UserId).set({ allow: true }, { merge: true }).then(() => {
                        // toast.success('Updated successfully.');
                        setisLoading(false);
                        history.push("/manage?reload=" + true);
                    }).catch((error) => console.log(error));
                   } else {
                    toast.success('Updated successfully.');
                    setisLoading(false);
                    history.push("/manage?reload=" + true);
                   }
                } else {
                    toast.success('Updated successfully.');
                    setisLoading(false);
                    history.push("/manage?reload=" + true);
                }

            }).catch((error) => {
                console.log("update error :", error);
                toast.success('Updated Failed.');
                setisLoading(false);
            })
    }

    const onFileChange = (e) => {
        if (e.currentTarget.files[0]) {
            let img = e.currentTarget.files[0];
            setFieldValue("photoURL", URL.createObjectURL(img));
            try {
                const reader = new FileReader()
                reader.onload = () => {
                    setnewProfileImg(reader.result)
                }
                reader.readAsDataURL(img)
            } catch (error) {
                console.log("img :", error);
            }

        }
    }

    const passwordReset = () => {
        firebase.doPasswordReset(values.email).then(() => {
            toast.success('Password reset link has been sent on Therapist email.');
        }).catch((error) => {
            console.log(error);
        })
    }

    const onCheck = (e) => {
        setValues({ ...values, [e.target.name]: e.target.checked })
    }

    const getORGName = (data) => {
        try {
            if (ORGs && ORGs.length > 0) {
                let datax = ORGs.filter((org) => org.organizationID === data)
                return datax[0].name
            } else {
                return "";
            }
        } catch (error) {

        }
    }

    const userLimitChecker = async (data) => {

       await firebase.db.collection("organizations/" + data.id + "/administrators").get().then(function (querySnapshot) {
            const totalCount = querySnapshot.size;
            firebase.db.collection("organizations").doc(data.id).onSnapshot((doc) => {
                if (doc.data()) {
                    let permittedUsers = 0
                    if (doc.data().totalUser) {
                        permittedUsers = doc.data().totalUser
                        if (totalCount >= permittedUsers) {
                            setSweetAlert({
                                show: true,
                                type: "warning",
                                title: "No more user allowed.",
                                message: "Organization user limit has been reached. Please contact info@emerhelp.app for assistance.",
                                onConfirm: () => { setSweetAlert({ ...setSweetAlert, show: false }) },
                                onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
                            })

                        } else {
                            setValues({ ...values, organizationID: data.organizationID })
                        }
                    } else { 
                      setValues({ ...values, organizationID: data.organizationID })
                    }
                }
            }, error => {
                console.log(error)
            })
        });

    }

    const onSelect = (data) => {
        setORGFocus(false)

        if(oldOrgId === data.id){
            setValues({ ...values, organizationID: data.organizationID })
        } else {
            userLimitChecker(data)
        }
    }

    const onRemove = async (e) => {
        let id = (e.currentTarget.id);
        let refForms = firebase.db.collection("users/" + AuthUser.uid + "/consentforms").doc(id);
        await refForms.delete();
    }

    const onView = (e) => {
        let id = e.currentTarget.id;
        let fileToView = ConsentForms.filter((data) => data.id === id);
        window.localStorage.setItem("pdflink", fileToView[0].formURL);
        history.push("/consent-forms/" + id);
    }

    const onConsentFormSubmit = async (file) => {
        let fileObj = { path: "therapist/consentForm/", img: file, firebase: firebase }
        let refForms = firebase.db.collection("users/" + AuthUser.uid + "/consentforms").doc();
        let ConsentFormOBJ = {
            id: refForms.id,
            title: file.name,
        }
        await FileUpload(fileObj).then((data) => {
            ConsentFormOBJ.formURL = data
            refForms.set(ConsentFormOBJ, { merge: true }).then(() => setConsentFormModalShow(false)).catch((error) => console.log(error))
        });
    }
    return (
        <>
            {isLoading ?
                <div className="loading">
                    <div class="loader"></div>
                </div> : null}
            <form onSubmit={handleSubmit}>
                <div className="dashboard-area">
                    <div className="list-section">
                        <div className="title">
                            <h2>Edit Therapist Profile</h2>
                            <div className="title-btn">
                                <div className="link-btn">
                                    <button onClick={() => history.push("/manage")}> Cancel </button>
                                </div>
                                <div className="add-patient">
                                    <button type="submit"> Save </button>
                                </div>
                            </div>
                        </div>

                        <div className="form-section">
                            <div className="left">
                                <div className="title-lbl">
                                    Profile Picture.
                                </div>

                                <div className="profile-img">
                                    <div className="img">
                                        <img src={values.photoURL ? values.photoURL : logo} alt="" />
                                    </div>
                                    <div className="btn">
                                        <span>Select Photo</span>
                                        <input type="file" accept='image/*'
                                            name="" id="" onChange={onFileChange} />
                                    </div>
                                </div>

                                <div className="title-lbl">
                                    General Details.
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Name :</label>
                                    <input type="text"
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name}
                                        className={touched.name && errors.name === true ? "err" : null}
                                    />
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">Email :</label>
                                    <input type="text"
                                        name="email"
                                        disabled
                                        value={values.email}
                                    />
                                </div>
                            </div>

                            <div className="right">
                                <div className="title-lbl">
                                    Organization Info.
                                </div>
                                <div className="input-wrap select-wrap">
                                    <label htmlFor="">Organization ID : {values.organizationID}</label>
                                </div>
                                <div className="input-wrap select-wrap">
                                    <input type="text"
                                        autocomplete="off"
                                        placeholder="Select Organization"
                                        name="organizationID"
                                        onChange={handleChange}
                                        value={getORGName(values.organizationID)}
                                        onFocus={() => setORGFocus(true)}
                                        className={touched.name && errors.name === true ? "err" : null}
                                    />

                                    <div className="select-box" onBlur={() => setORGFocus(false)} onFocus={() => setORGFocus(true)} style={ORGFocus ? { display: "block" } : { display: "none" }}>
                                        <ul className="option">
                                            <li className="disable">Select</li>
                                            {ORGs && ORGs.length > 0 ?
                                                ORGs.map((org, index) => {
                                                    return (<li onClick={() => onSelect(org)}>{org.name}</li>)
                                                })
                                                : null}
                                        </ul>
                                    </div>
                                </div>

                                <div className="title-lbl">
                                    General Setting.
                                </div>
                                <div className="pwd-reset">
                                    <span onClick={passwordReset}><i className="fa fa-unlock-alt" aria-hidden="true"></i> Password Reset</span>
                                </div>

                                <div className="title-lbl">
                                    Profile Info.
                                </div>
                                <div className="info">
                                    <span> <b>Subscription :&nbsp;</b>
                                        <p className={!values.subscriptionEnabled ? "active" : ""}> Disable&nbsp;<i className="fa fa-ban" aria-hidden="true"></i> </p>
                                        <div className="switch"><Switch height={20} name={"subscriptionEnabled"} checked={values.subscriptionEnabled} onChange={onCheck} /></div>
                                        <p className={values.subscriptionEnabled ? "active" : ""}> Enable&nbsp;<i className="fa fa-check" aria-hidden="true"></i> </p>
                                    </span>

                                    <br />
                                    <span> <b>Profile disable :&nbsp;</b>
                                        <p className={!values.profileDisable ? "active" : ""}> Enable&nbsp;<i className="fa fa-check" aria-hidden="true"></i> </p>
                                        <div className="switch"><Switch height={20} name={"profileDisable"} checked={values.profileDisable} onChange={onCheck} /></div>
                                        <p className={values.profileDisable ? "active" : ""}> Disable&nbsp;<i className="fa fa-ban" aria-hidden="true"></i> </p>
                                    </span>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="form-section list-section">
                        <div className="title-lbl">
                            Consent Forms.

                            <button onClick={() => setConsentFormModalShow(true)} type="button"> Add New </button>
                        </div>

                        <List
                            data={ConsentForms}
                            onView={onView}
                            onRemove={onRemove}
                        />
                    </div>
                </div>
            </form>
            <ConsentFormModal
                show={ConsentFormModalShow}
                onClose={() => setConsentFormModalShow(false)}
                onSave={onConsentFormSubmit} />

             <CustomSweetAlert {...SweetAlert} />
        </>
    )
}

export default withFirebase(EditTherapist);

const formValues = {
    name: "",
    email: "",
    photoURL: "",
}

function List(props) {
    const { data, onView, onRemove } = props
    return (
        <div className="table">
            <table>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Forms</th>
                        <th></th>
                        <th colSpan="3"> Options </th>
                    </tr>
                </thead>
                <tbody>
                    {data ? data.length > 0 ? data.map((item, index) =>
                        <tr>
                            <td className="opt">{index + 1}</td>
                            <td colSpan="2">{item.title}</td>
                            <td className="opt">
                                <span id={item.id} onClick={onView}>
                                    <i className="fa fa-eye" aria-hidden="true"></i></span>
                            </td>
                            <td className="opt">
                                <span id={item.id} onClick={onRemove}>
                                    <i className="fa fa-trash-o" aria-hidden="true"></i></span>
                            </td>
                        </tr>) :
                        <tr>
                            <td colSpan="4">No Record Found.</td>
                        </tr>
                        :
                        <tr>
                            <td colSpan="4">Loading..</td>
                        </tr>}
                </tbody>
            </table>
        </div>
    )
}
