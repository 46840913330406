import React, { useState } from 'react'
import "./style.css";

function ConsentFormModal(props) {
    const { show, onClose, onSave } = props;
    const [File, setFile] = useState(null);
    const [isNotFile, setisNotFile] = useState(false)
    const onFileChange = (e) => {
        if (e.currentTarget.files[0]) {
            let file = e.currentTarget.files[0];
            setFile(file);
        }
    }
    const onSubmit = () => {
        if(File){
            onSave(File)
        } else {
            setisNotFile(true)
        }
    }
    const onHide=() =>{
        setFile(null);
        setisNotFile(false)
        onClose();
    }
    return (
        <>
            <div className={show ? "emer-overlay _show" : "emer-overlay _hide"} onClick={onHide}></div>
            <div className={show ? "emer-modal _show" : "emer-modal _hide"}>
                <div className="emer-modal-title">
                    upload your Consent Form
                </div>
                <div className="emer-modal-body">
                    <div className="file-box">
                        <input type="file" name="" id="" onChange={onFileChange} />
                        <div className="file-content"><div className="title">{
                        File ? File.name :"drag and drop files here or click to browse"}</div>
                        {isNotFile && <h4 className="nofile-msg">No file selected</h4>}
                        </div>
                    </div>
                </div>
                <div className="emer-modal-foot">
                    <button onClick={onHide}>Close</button>
                    <button onClick={onSubmit}>Upload</button>
                </div>
            </div>
        </>
    )
}

export default ConsentFormModal
