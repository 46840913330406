import React, { useEffect, useState } from 'react'
import logo from "../../assets/image/logo.png";
import search from "../../assets/image/search.png";
import { withFirebase } from '../../services';
import { Switch } from 'evergreen-ui';
import "./style.css";
import { history } from '../../common';

function TherapistList(props) {
    const [therapistList, settherapistList] = useState(null)
    const { firebase } = props;
    const [authUser, setauthUser] = useState(null);
    useEffect(() => {
        if (firebase.authUser) {
            if (!firebase.authUser.isAdmin) {
                history.push("/dashboard");
            }
            setauthUser(firebase.authUser);
        }
        return () => {
        }
    }, [firebase.authUser]);

    useEffect(() => {
        if (authUser) {
            getAllTherapist();
        }
        return () => {

        }
    }, [authUser])

    const getAllTherapist = async () => {
        let ref = firebase.db.collection("users")
            .where("accountType", "==", 2);
        ref.onSnapshot((data) => {
            let therapist = []
            data.forEach(function (doc) {
                therapist = therapist.concat({ id: doc.id, ...doc.data() })
            });
            settherapistList(therapist);
        });
    };

    const onAction = (id, action) => {
        switch (action) {
            case "VIEW":
                break;
            case "EDIT":
                break;
            case "DELETE":
                break;
            default:
                break;
        }
    }

    const onDelete = async (id) => {
        // const deleteAuthUser = firebase.functions.httpsCallable('deleteAuthUser');
        // await deleteAuthUser({ uid: id }).then(async (data) => {
        //     await firebase.db.collection(`users`).doc(id).delete();
        // }).catch((error) => console.log(error))
    }
    return (
        <>
            <div className="dashboard-area">
                <div className="list-section">
                    <div className="title">
                        <h2>Therapist List</h2>
                        <div className="title-btn">
                            <div className="search-input">
                                <input type="text" placeholder="Search" />
                                <div className="img">
                                    <img src={search} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <List data={therapistList}
                        onAction={onAction}
                        firebase={firebase} />
                </div>
            </div>
        </>
    )
}

export default withFirebase(TherapistList);

function List(props) {
    const [isCheck, setisCheck] = useState(false);
    const { data, onAction, firebase } = props

    const onCheck = async (e) => {
        let ref = firebase.db.collection("users").doc(e.target.name);
        setisCheck({ ...isCheck, [e.target.name]: e.target.checked })
        await ref.get().then((data) => {
            let newData = data.data();
            newData.subscriptionEnabled = !e.target.checked;
            ref.set(newData, { merge: true }).then(() => console.log("Done")).catch((error) => console.log(error));
        })
    }


    return (
        <div className="table">
            <table>
                <thead>
                    <tr>
                        <th>Photo</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Subscription</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data ? data.length > 0 ? data.map((item) => {
                        return (<ListRow item={item} onCheck={onCheck} firebase={firebase} />)
                    }) :
                        <tr>
                            <td colSpan="4">No Record Found.</td>
                        </tr>
                        :
                        <tr>
                            <td colSpan="4">Loading..</td>
                        </tr>}
                </tbody>
            </table>
        </div>
    )
}

const ListRow = (props) => {
    const { item, onCheck, firebase } = props
    const [isLoading, setIsLoading] = useState(false);
    const DisableAuthUser = async () => {
        setIsLoading(true);
        let ref = firebase.db.collection("users").doc(item.id);
        const disableAuthUser = firebase.functions.httpsCallable('disableAuthUser');
        await disableAuthUser({ uid: item.id, isDisable: !item.isDisabled }).then(async (data) => {
            await ref.get().then((data) => {
                let newData = data.data();
                newData.isDisabled = !item.isDisabled;
                ref.set(newData, { merge: true }).then(() => { console.log("Done"); setIsLoading(false); }).catch((error) => console.log(error));
            })
        }).catch((error) => { console.log(error); setIsLoading(false); })
    }

    return (
        item ?
            <tr>
                <td className="img"><img src={item.photoURL ? item.photoURL : logo} alt="" /></td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td className="option">
                    <div className="switch">
                        <Switch name={item.id} height={20} checked={item.subscriptionEnabled} onChange={onCheck} />
                    </div>
                </td>
                <td>
                    <button onClick={DisableAuthUser} className={item.isDisabled ? "enable-authuser-btn" : "disable-authuser-btn"}>
                        {isLoading ?
                            <><i className="fa fa-spinner" aria-hidden="true"></i> Loading..</>
                            : item.isDisabled ?
                                <><i className="fa fa-check" aria-hidden="true"></i> Enable </>
                                : <> <i className="fa fa-ban" aria-hidden="true"></i> Disable </>} </button>
                </td>
            </tr> : null
    );
}