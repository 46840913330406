import React from 'react'

function Footer() {
    return (
        <>
            <section>
                <footer>
                    <div className="container">
                        <div className="footer">
                            <div className="copyright"> Copyright © 2021 Emerhelp </div>
                        </div>
                    </div>
                </footer>
            </section>
        </>
    )
}

export default Footer
