import React, { useRef, useState } from 'react'
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";

function CustomPopover(props) {
    const { id, onAction } = props;
    const [show, setShow] = useState(false);
    const target = useRef(null);
    return (
        <>
            <p className="clickable" ref={target} style={{ cursor: 'pointer' }} onClick={(event) => {
                setShow(!show)
            }}>
                <span />
                <span />
                <span />
            </p>

            <Overlay target={target.current} show={show} placement="left">
                {(props) => (
                    <Popover {...props} id="popover-contained">
                        <Popover.Content className="popover-content">
                            {/* <h3 onClick={() => {
                                setShow(false)
                                onAction(id, 'VIEW')
                            }}>
                                <span><i className="fa fa-eye"></i> View</span></h3> */}
                            <h3 onClick={() => {
                                setShow(false)
                                onAction(id, 'EDIT')
                            }}>
                                <span><i className="fa fa-edit"></i> Edit</span></h3>
                            <h3 onClick={() => {
                                setShow(false)
                                onAction(id, 'DELETE')
                            }}>
                                <span><i className="fa fa-trash"></i> Delete</span></h3>
                        </Popover.Content>
                    </Popover>)}
            </Overlay>

        </>
    )
}

export default CustomPopover;