import React, { useState } from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
//img
import { withFirebase } from '../../services';
import logo from "../../assets/image/logo.png";

function Header(props) {
    const { firebase, user } = props;
    const [ShowMenu, setShowMenu] = useState(false)

    const doLogout = () => {
        firebase.dosignOut();
    }
    const UserMenu = () => {
        setShowMenu(!ShowMenu)
    }
    return (
        <section className="dashboard-header">
            <ToastContainer />
            <div className="container">
                <div className="head">
                    <div className="logo">
                        <a href="/dashboard">
                            <img src={logo} alt="" />
                        </a>
                    </div>
                    <div className="title">
                        <h2>EmerHelp Dashboard</h2>
                    </div>
                    <div className="user-div">
                        <div className="img">
                            <img src={user.photoURL} alt="" />
                        </div>
                        <div className="info" onClick={UserMenu}>
                            <h4>{user.name}</h4>
                            <span>Therapist</span>
                        </div>
                        <div className="user-menu-btn" onClick={UserMenu}>
                            <i className="fa fa-caret-down" aria-hidden="true"></i>
                        </div>

                        <div className="user-menu" style={ShowMenu ? { display: "flex" } : { display: "none" }}>
                            <ul>
                                {user.isAdmin ?
                                    <>
                                        {/* <li> <a href="/therapist">Therapist </a></li> */}
                                        <li> <a href="/dashboard">Clients </a></li>
                                    </>
                                    : null}

                                <li> <a href="/manage"> manage Therapists </a></li>
                                {user.isAdmin && <li> <a href="/manage-organization"> manage Organization </a></li>}
                                <li> <a href="/user-profile"> Profile </a></li>
                                <li>
                                    <span onClick={doLogout}> Logout </span>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default withFirebase(Header)
