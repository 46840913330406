import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { FileUploadWithURL,FileUpload,  history } from '../../common';
import logo from "../../assets/image/logo.png";
import { withFirebase } from '../../services';
import { toast } from 'react-toastify';
import ConsentFormModal from './Organizations/ConsentFormModal';

function UserProfile(props) {
    const { firebase } = props;
    const [AuthUser, setAuthUser] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [newProfileImg, setnewProfileImg] = useState(false);
    const [ConsentFormModalShow, setConsentFormModalShow] = useState(false);
    const [ConsentForms, setConsentForms] = useState(null);

    const ProfileForm = useFormik({
        initialValues: formValues,
        onSubmit: values => {
            onSubmit(values);
        }
    });

    useEffect(() => {
        setisLoading(true);
        if (firebase.authUser) {
            const { name, email, photoURL, subscriptionEnabled , organizationID } = firebase.authUser
            setValues({
                name: name,
                email: email,
                photoURL: photoURL,
                subscriptionEnabled: subscriptionEnabled ? subscriptionEnabled : false,
                organizationID: organizationID
            });
            setAuthUser(firebase.authUser);
            setisLoading(false);
        }
        return () => {

        }
    }, [firebase.authUser])

    const onRemove = async (e) => {
        let id = (e.currentTarget.id);
        let refForms = firebase.db.collection("users/" + AuthUser.uid + "/consentforms").doc(id);
        await refForms.delete();
    }

    const onView = (e) => {
        let id = e.currentTarget.id;
        let fileToView = ConsentForms.filter((data) => data.id === id);
        window.localStorage.setItem("pdflink", fileToView[0].formURL);
        history.push("/consent-forms/" + id);
    }

    const onSubmit = async (data) => {
        setisLoading(true);
        let imgProps = {
            path: "profileImage",
            img: newProfileImg,
            firebase: firebase,
            name: data.name
        }

        if (newProfileImg) {
            await FileUploadWithURL(imgProps).then((url) => {
                updateUserProfile(data, url);
            }).catch((error) => { setisLoading(false); console.log("IMG URL", error) })
        } else {
            updateUserProfile(data, "");
        }
    }

    let { handleSubmit, values, handleChange, setFieldValue, touched, errors, setValues } = ProfileForm;

    const updateUserProfile = (data, photoURL) => {
        if (photoURL) { data.photoURL = photoURL; }
        let ref = firebase.db.collection('users').doc(AuthUser.uid);
        ref.set(data, { merge: true })
            .then(() => {
                toast.success('Updated successfully.');
                setisLoading(false);
            }).catch((error) => {
                console.log("update error :", error);
                toast.success('Updated Failed.');
                setisLoading(false);
            })
    }

    const onFileChange = (e) => {
        if (e.currentTarget.files[0]) {
            let img = e.currentTarget.files[0];
            setFieldValue("photoURL", URL.createObjectURL(img));
            try {
                const reader = new FileReader()
                reader.onload = () => {
                    setnewProfileImg(reader.result)
                }
                reader.readAsDataURL(img)
            } catch (error) {
                console.log("img :", error);
            }

        }
    }

    const passwordReset = () => {
        firebase.doPasswordReset(AuthUser.email).then(() => {
            toast.success('Password reset link has been sent to your email.');
        }).catch((error) => {
            console.log(error);
        })
    }

    const onConsentFormSubmit = async (file) => {
        let fileObj = { path: "therapist/consentForm/", img: file, firebase: firebase }
        let refForms = firebase.db.collection("users/" + AuthUser.uid + "/consentforms").doc();
        let ConsentFormOBJ = {
            id: refForms.id,
            title: file.name,
        }
        await FileUpload(fileObj).then((data) => {
            ConsentFormOBJ.formURL = data
            refForms.set(ConsentFormOBJ, { merge: true }).then(() => setConsentFormModalShow(false)).catch((error) => console.log(error))
        });
    }
    return (
        <>
            {isLoading ?
                <div className="loading">
                    <div class="loader"></div>
                </div> : null}
            <form onSubmit={handleSubmit}>
                <div className="dashboard-area">
                    <div className="list-section">
                        <div className="title">
                            <h2>Edit Your Profile</h2>
                            <div className="title-btn">
                                <div className="link-btn">
                                    <button onClick={() => history.push("/dashboard")}> Cancel </button>
                                </div>
                                <div className="add-patient">
                                    <button type="submit"> Save </button>
                                </div>
                            </div>
                        </div>

                        <div className="form-section">
                            <div className="left">
                                <div className="title-lbl">
                                    Profile Picture.
                                </div>

                                <div className="profile-img">
                                    <div className="img">
                                        <img src={values.photoURL ? values.photoURL : logo} alt="" />
                                    </div>
                                    <div className="btn">
                                        <span>Select Photo</span>
                                        <input type="file" accept='image/*'
                                            name="" id="" onChange={onFileChange} />
                                    </div>
                                </div>

                                <div className="title-lbl">
                                    General Details.
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Name :</label>
                                    <input type="text"
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name}
                                        className={touched.name && errors.name === true ? "err" : null}
                                    />
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">Email :</label>
                                    <input type="text"
                                        name="email"
                                        disabled
                                        value={values.email}
                                    />
                                </div>
                            </div>

                            <div className="right">
                                <div className="title-lbl">
                                    Organization Info.
                                </div>
                                <div className="pwd-reset">
                                    <label htmlFor="">Organization ID : {values.organizationID}</label>
                                </div>

                                <div className="title-lbl">
                                    General Setting.
                                </div>
                                <div className="pwd-reset">
                                    <span onClick={passwordReset}><i className="fa fa-unlock-alt" aria-hidden="true"></i> Password Reset</span>
                                </div>

                                <div className="title-lbl">
                                    Profile Info.
                                </div>
                                <div className="info">
                                    <span>Your Subscription :&nbsp; <b>{values.subscriptionEnabled ?
                                        <> Enabled <i className="fa fa-check" aria-hidden="true"></i></>
                                        : <> Disable <i className="fa fa-ban" aria-hidden="true"></i> </>}</b> </span>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="form-section list-section">
                        <div className="title-lbl">
                            Consent Forms.

                            <button onClick={() => setConsentFormModalShow(true)} type="button"> Add New </button>
                        </div>

                        <List
                            data={ConsentForms}
                            onView={onView}
                            onRemove={onRemove}
                        />
                    </div>
                </div>
            </form>
            <ConsentFormModal
                show={ConsentFormModalShow}
                onClose={() => setConsentFormModalShow(false)}
                onSave={onConsentFormSubmit} />
        </>
    )
}

export default withFirebase(UserProfile);

const formValues = {
    name: "",
    email: "",
    photoURL: "",
}

function List(props) {
    const { data, onView, onRemove } = props
    return (
        <div className="table">
            <table>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Forms</th>
                        <th></th>
                        <th colSpan="3"> Options </th>
                    </tr>
                </thead>
                <tbody>
                    {data ? data.length > 0 ? data.map((item, index) =>
                        <tr>
                            <td className="opt">{index + 1}</td>
                            <td colSpan="2">{item.title}</td>
                            {/* <td className="opt">
                                <span>
                                    <i class="fa fa-download" aria-hidden="true"></i>
                                </span>
                            </td> */}
                            <td className="opt">
                                <span id={item.id} onClick={onView}>
                                    <i className="fa fa-eye" aria-hidden="true"></i></span>
                            </td>
                            <td className="opt">
                                <span id={item.id} onClick={onRemove}>
                                    <i className="fa fa-trash-o" aria-hidden="true"></i></span>
                            </td>
                        </tr>) :
                        <tr>
                            <td colSpan="4">No Record Found.</td>
                        </tr>
                        :
                        <tr>
                            <td colSpan="4">Loading..</td>
                        </tr>}
                </tbody>
            </table>
        </div>
    )
}