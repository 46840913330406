import { Switch } from 'evergreen-ui';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { history } from '../../../common';
import { withFirebase } from '../../../services';

const formValues = {
    name: "",
    orgAdminID: "",
    address: "",
    subscriptionEnabled: "",
    totalUser: "",
}

const validateSchema = (values) => {
    let errors = {};
    if (!values.name) {
        errors.name = true;
    }
    if (!values.orgAdminID) {
        errors.orgAdminID = true;
    }
    if (!values.address) {
        errors.address = true;
    }
    // if (!values.totalUser) {
    //     errors.totalUser = true;
    // }
    return errors;
}

function OrganizationsForm(props) {
    const { firebase, match } = props;
    const [AuthUser, setAuthUser] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [AllTherapist, setAllTherapist] = useState(null);
    const [editID, setEditID] = useState(null);
    const ProfileForm = useFormik({
        initialValues: formValues,
        validate: validateSchema,
        onSubmit: values => {
            onFormSubmit(values);
        }
    });

    useEffect(() => {
        if (match && match.params) {
            setEditID(match.params.id)
        }
        return () => {

        }
    }, [match])

    useEffect(() => {
        if (firebase.authUser) {
            setAuthUser(firebase.authUser)
        }
        return () => {

        }
    }, [firebase.authUser])

    useEffect(() => {
        setisLoading(true);
        if (AuthUser) {
            getAllTherapist();
            if (editID) {
                getORGData();
            }
        }
        return () => {

        }
    }, [AuthUser])

    const getORGData = async () => {
        let Ref = firebase.db.collection("organizations").doc(editID);
        await Ref.get().then((data) => {
            let x = { ...data.data() }
            setValues(x);
            setisLoading(false);
        })
    }

    const getAllTherapist = async () => {
        let ref = firebase.db.collection("users")
            .where("accountType", "==", 2);
        ref.onSnapshot((data) => {
            let therapist = []
            data.forEach(function (doc) {
                therapist = therapist.concat({ id: doc.id, ...doc.data() })
            });
            setAllTherapist(therapist);
            if(!editID){
                setisLoading(false);
            }
        });
    };

    const onCheck = (e) => {
        setValues({ ...values, [e.target.name]: e.target.checked })
    }

    const onFormSubmit = (data) => {
        let Ref = editID ? firebase.db.collection("organizations").doc(editID) :
            firebase.db.collection("organizations").doc();
        let dataToSave = { ...data }
        dataToSave.organizationID = Ref.id
        Ref.set(dataToSave, { merge: true })
            .then(() => {
                if (editID) {
                    toast.success('Organization Edited successfully.');
                } else {
                    toast.success('New organization Added successfully.');
                }
                setTimeout(() => {
                    history.push("/manage-organization");
                    setisLoading(false);
                }, 2000);
            })
            .catch(function (error) {
                console.log("Error",);
                setisLoading(false);
                toast.error('Patient Registration Fail');
            });
    }

    let { handleSubmit, values, handleChange, setFieldValue, touched, errors, setValues } = ProfileForm;
    return (
        <>
            {isLoading ?
                <div className="loading">
                    <div class="loader"></div>
                </div> : null}
            <form onSubmit={handleSubmit}>
                <div className="dashboard-area">
                    <div className="list-section">
                        <div className="title">
                            <h2>New Organization</h2>
                            <div className="title-btn">
                                <div className="link-btn">
                                    <button onClick={() => history.push("/manage-organization")}> Cancel </button>
                                </div>
                                <div className="add-patient">
                                    <button type="submit"> Save </button>
                                </div>
                            </div>
                        </div>

                        <div className="form-section">
                            <div className="left">
                                <div className="title-lbl">
                                    General Details.
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Name :</label>
                                    <input type="text"
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name}
                                        className={errors.name === true ? "err" : null}
                                    />
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">Admin :</label>
                                    <select name="orgAdminID" id=""
                                        onChange={handleChange}
                                        className={errors.name === true ? "err" : null}>
                                        {AllTherapist && AllTherapist.length > 0 &&
                                            AllTherapist.map((data, index) =>
                                                <option key={index} value={data.id} 
                                                    selected={values.orgAdminID === data.id}
                                                >{data.name}</option>
                                            )

                                        }
                                    </select>
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor=""> Address :</label>
                                    <input type="text"
                                        name="address"
                                        onChange={handleChange}
                                        value={values.address}
                                        className={errors.address === true ? "err" : null}
                                    />
                                </div>
                            </div>

                            <div className="right">
                                <div className="title-lbl">
                                    General Setting.
                                </div>
                                <div className="info">
                                    <span> <b>Subscription :&nbsp;</b>
                                        <p className={!values.subscriptionEnabled ? "active" : ""}> Disable&nbsp;<i className="fa fa-ban" aria-hidden="true"></i> </p>
                                        <div className="switch"><Switch height={20} name={"subscriptionEnabled"} checked={values.subscriptionEnabled} onChange={onCheck} /></div>
                                        <p className={values.subscriptionEnabled ? "active" : ""}> Enable&nbsp;<i className="fa fa-check" aria-hidden="true"></i> </p>
                                    </span>
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">No. of Users :</label>
                                    <input type="number"
                                        name="totalUser"
                                        onChange={handleChange}
                                        value={values.totalUser}
                                        className={errors.totalUser === true ? "err" : null}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </>
    )
}

export default withFirebase(OrganizationsForm)
