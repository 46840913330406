import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { FileUploadWithURL, history } from '../../common';
import { withFirebase } from '../../services';
import { FirebaseApp } from '../../services/firebase/Firebase';
import logo from "../../assets/image/logo.png";
import "./style.css";

function AddPatient(props) {
    const { firebase, match } = props;
    const [newProfileImg, setnewProfileImg] = useState(false);
    const [authUser, setauthUser] = useState(null);
    const [EditID, setEditID] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    useEffect(() => {
        if (match.params.id) {
            setEditID(match.params.id);
            getEditUserData(match.params.id);
        }
        return () => {

        }
    }, [match.params.id]);

    useEffect(() => {
        if (firebase.authUser) {
            setauthUser(firebase.authUser);
        }
        return () => {
        }
    }, [firebase.authUser]);

    const getEditUserData = async (id) => {
        setisLoading(true);
        let Ref = firebase.db.collection("users").doc(id);
        await Ref.get().then((data) => {
            let x = { ...data.data() }
            delete x.therapist;
            setValues(x);
            setisLoading(false);
        })
    }

    const PatientForm = useFormik({
        initialValues: formValues,
        onSubmit: values => {
            onSubmit(values);
        },
        validate: validate
    });
    let { handleSubmit, values, handleChange, setFieldValue, touched, errors, setValues } = PatientForm;

    const onFileChange = (e) => {
        if (e.currentTarget.files[0]) {
            let img = e.currentTarget.files[0];
            setFieldValue("photoURL", URL.createObjectURL(img));
            try {
                const reader = new FileReader()
                reader.onload = () => {
                    setnewProfileImg(reader.result)
                }
                reader.readAsDataURL(img)
            } catch (error) {
                console.log("img :", error);
            }

        }
    }

    const onSubmit = async (data) => {
        setisLoading(true);
        let imgProps = {
            path: "profileImage",
            img: newProfileImg,
            firebase: firebase,
            name: data.name
        }

        if (newProfileImg) {
            await FileUploadWithURL(imgProps).then((url) => {
                PatientRegistration(data, url);
            }).catch((error) => { setisLoading(false); console.log("IMG URL", error) })
        } else {
            PatientRegistration(data, "");
        }
    }

    const PatientRegistration = (data, photoURL) => {
        if (EditID) {
            if (photoURL) { data.photoURL = photoURL; }
            CreatePatientUser(EditID, data)
        } else {
            data.photoURL = photoURL;
            CreateAuthUser(data);
        }

    }

    let APP;
    async function CreateAuthUser(data) {
        if (APP === undefined) {
            APP = new FirebaseApp();
        }
        let isSucess = false;
        await APP.auth.createUserWithEmailAndPassword(data.email, "R@ndompassWoRd#321")
            .then(function (user) {
                CreatePatientUser(user.user.uid, data)
                PasswordResetMail(data.email)
                isSucess = user.user.uid;
                APP.auth.currentUser.sendEmailVerification();
                APP.auth.signOut();
            }).catch(function (error) {
                console.log(error);
                toast.error(error.message);
                isSucess = false;
                setisLoading(false);
            });
        return isSucess;
    }

    const CreatePatientUser = async (uid, data) => {
        let Ref = firebase.db.collection("users").doc(uid);
        let dataToSave = { ...data };
        dataToSave.userID = uid;
        dataToSave.therapist = authUser.ref;

        Ref.set(dataToSave, { merge: true })
            .then(() => {
                console.log("successfully.");
                if (EditID) {
                    toast.success('Patient Data Edited successfully.');
                } else {
                    toast.success('Patient Registration success.');
                }
                setTimeout(() => {
                    history.push("/dashboard");
                    setisLoading(false);
                }, 2000);
            })
            .catch(function (error) {
                console.log("Error",);
                setisLoading(false);
                toast.error('Patient Registration Fail');
            });
    }

    function PasswordResetMail(email) {
        APP.auth
            .sendPasswordResetEmail(email)
            .then(() => {
            })
            .catch((error) => {
                console.log("Mail Error", error)
            });
    }

    return (
        <>
            {isLoading ?
                <div className="loading">
                    <div class="loader"></div>
                </div> : null}
            <form onSubmit={handleSubmit}>
                <div className="dashboard-area">
                    <div className="list-section">
                        <div className="title">
                            <h2>{EditID ? "Edit" : "New"} Patient</h2>
                            <div className="title-btn">
                                <div className="link-btn">
                                    <button onClick={() => history.push("/dashboard")}> Cancel </button>
                                </div>
                                <div className="add-patient">
                                    <button type="submit"> Save </button>
                                </div>
                            </div>
                        </div>

                        <div className="form-section">
                            <div className="left">
                                <div className="title-lbl">
                                    Profile Picture.
                                </div>

                                <div className="profile-img">
                                    <div className="img">
                                        <img src={values.photoURL ? values.photoURL : logo} alt="" />
                                    </div>
                                    <div className="btn">
                                        <span>Select Photo</span>
                                        <input type="file" accept='image/*'
                                            name="" id="" onChange={onFileChange} />
                                    </div>
                                </div>

                                <div className="title-lbl">
                                    General Details.
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Name :</label>
                                    <input type="text"
                                        name="name"
                                        onChange={handleChange}
                                        value={values.name}
                                        className={touched.name && errors.name === true ? "err" : null}
                                    />
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">Email :</label>
                                    <input type="text"
                                        name="email"
                                        onChange={handleChange}
                                        value={values.email}
                                        className={touched.email && errors.email === true ? "err" : null}
                                    />
                                </div>
                            </div>

                            <div className="right">
                                <div className="title-lbl">
                                    Address.
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Street :</label>
                                    <input type="text"
                                        name="street"
                                        onChange={handleChange}
                                        value={values.street}
                                        className={touched.street && errors.street === true ? "err" : null}
                                    />
                                </div>
                                <div className="wrap">
                                    <div className="input-wrap">
                                        <label htmlFor="">City :</label>
                                        <input type="text"
                                            name="city"
                                            onChange={handleChange}
                                            value={values.city}
                                            className={touched.city && errors.city === true ? "err" : null}
                                        />
                                    </div>
                                    <div className="input-wrap">
                                        <label htmlFor="">State :</label>
                                        <input type="text"
                                            name="state"
                                            onChange={handleChange}
                                            value={values.state}
                                            className={touched.state && errors.state === true ? "err" : null}
                                        />
                                    </div>
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">ZIP Code :</label>
                                    <input type="number"
                                        name="zipCode"
                                        onChange={handleChange}
                                        value={values.zipCode}
                                        className={touched.zipCode && errors.zipCode === true ? "err" : null}
                                    />
                                </div>

                                <div className="title-lbl">
                                    Emergency Contact.
                                </div>
                                <div className="input-wrap">
                                    <label htmlFor="">Name :</label>
                                    <input type="text"
                                        name="emergencyContactName"
                                        onChange={handleChange}
                                        value={values.emergencyContactName}
                                        className={touched.emergencyContactName && errors.emergencyContactName === true ? "err" : null}
                                    />
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">Relationship :</label>
                                    <input type="text"
                                        name="emergencyContactRelationship"
                                        onChange={handleChange}
                                        value={values.emergencyContactRelationship}
                                        className={touched.emergencyContactRelationship && errors.emergencyContactRelationship === true ? "err" : null}
                                    />
                                </div>

                                <div className="input-wrap">
                                    <label htmlFor="">Phone :</label>
                                    <input type="number"
                                        name="emergencyContactPhoneNumber"
                                        onChange={handleChange}
                                        value={values.emergencyContactPhoneNumber}
                                        className={touched.emergencyContactPhoneNumber && errors.emergencyContactPhoneNumber === true ? "err" : null}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </form>
        </>
    )
}

export default withFirebase(AddPatient);


const formValues = {
    accountType: 1,
    name: "",
    email: "",
    photoURL: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    emergencyContactName: "",
    emergencyContactPhoneNumber: "",
    emergencyContactRelationship: "",
}

const validate = (values) => {
    let errors = {};
    if (!values.name) {
        errors.name = true;
    }
    if (!values.email) {
        errors.email = true;
    }
    return errors;
}